const INVOICE_PATH_PATTERN = /(\/payment\/history\/invoices\/)(.*)/;
const EDIT_RECURRING_PAYMENT_PATH_PATTERN = /(\/payment\/pay\/recurring\/)(.*)/;

let analyticsPageFactory = {};

analyticsPageFactory.getPageObject = pathname => {
  pathname = pathname.replace('#', '');
  switch (pathname) {
    case '/payment':
      return createPageObject('Account Summary', 'Payment');
    case '/payment/pay':
      return createPageObject('payment/split payment/confirmation', 'Payment', 'make payment');
    case '/payment/pay/recurring':
      return createPageObject('Recurring Payment', 'Payment');
    case '/payment/history/invoices':
      return createPageObject('Invoice History', 'Payment', 'invoices');
    case '/payment/summary/invoices':
      return createPageObject('invoices', 'Payment', 'invoices');
    case '/payment/summary/cards':
      return createPageObject('payment/payment cards', 'Payment');
    case '/payment/summary/history':
      return createPageObject('payment/payment history', 'Payment');
    case '/payment/summary/subscription':
      return createPageObject('payment/subscription plan', 'Payment');
    case '/payment/history/payments':
      return createPageObject('Payments History', 'Payment','payment/payment history');
    case '/payment/manage':
      return createPageObject('payment/split payment/split payment request', 'Payment', 'split payment');
    case '/payment/summary/fuel':
      return createPageObject('payment/fuel card');
    case '/profile':
      return createPageObject('Profile', 'Profile');
    case '/profile/azure-settings':
      return createPageObject('Profile', 'Azure Credentials Change');
    case '/passwordReset':
      return createPageObject('Password Reset', 'Azure Password Change');
    case '/phonePasswordReset':
      return createPageObject('Phone Password Reset', 'Azure Password Change');
    case '/route':
      return createPageObject('Route', 'Route');
    case '/roster':
      return createPageObject('Roster', 'Roster');
    case '/trip-recording':
      return createPageObject('Vanpool Trip Recording', 'Trip Recording');
    case '/trip-recording/me':
      return createPageObject('My Trips', 'Trip Recording');
    case '/trip-recording/expenses':
      return createPageObject('Trip Expenses', 'Trip Recording');
    case '/vanpool':
      return createPageObject('vanpool/overview', 'Vanpool');
    case '/driver-application':
      return createPageObject('Driver Application', 'Driver Application');
    case '/dashboard':
      return createPageObject('Dashboard/main', 'Dashboard');
    case '/coordinator-application':
      return createPageObject(
        'Coordinator Application',
        'Coordinator Application'
      );
    case '/payment/fuel':
      return createPageObject('Fuel Card Summary', 'Payment');
    case '/payment/fuel/activated':
      return createPageObject('Fuel Card Activated', 'Payment');
    case '/JoinCommute/view-matched-vans':
      return createPageObject('matching vans/results', 'Find a Van', 'matching vans');
    case '/JoinCommute/stay-in-touch':
      return createPageObject('stay in touch/entry', 'Find a Van', 'stay in touch');
    case '/JoinCommute/stay-in-touch/thank-you':
      return createPageObject('stay in touch/thank you', 'Find a Van', 'stay in touch');
    case '/JoinCommute':
      return createPageObject('describe commute/tell about commute', 'Find a Van', 'describe commute');
    case '/JoinCommute/vanpool-details':
      return createPageObject('matching vans/van details', 'Find a Van', 'describe commute');
    case '/JoinCommute/choose-plan':
      return createPageObject('choose subscription/start', 'Find a Van', 'choose subscription');
    case '/JoinCommute/choose-plan/upgrade':
      return createPageObject('choose subscription/upgrade', 'Find a Van', 'choose subscription');
    case '/JoinCommute/choose-plan/summary':
      return createPageObject('choose subscription/summary', 'Find a Van', 'choose subscription');
    case '/JoinCommute/license/enter-license':
      return createPageObject('drivers license/not approved driver', 'Find a Van');
    case '/JoinCommute/add-payment':
      return createPageObject('payment/add new payment', 'Find a Van', 'add payment');
    case '/JoinCommute/add-payment/backup':
      return createPageObject('payment/add backup card', 'Find a Van', 'add payment');
    case '/JoinCommute/apply/coordinator':
      return createPageObject('apply to coordinate/entry', 'Find a Van', 'apply to coordinate');
    case '/JoinCommute/summary':
      return createPageObject('payment/payment summary', 'Find a Van', 'add payment');
    case '/JoinCommute/onboarding':
      return createPageObject('customer onboarding/your seat is saved', 'Find a Van', 'customer onboarding');
    case  '/JoinCommute/verify/home-address':
      return createPageObject('verify home address', 'Find a Van', 'verify home address');
    case '/myprofile':
      return createPageObject('profile/main', 'profile overview');
    case '/myprofile/edit':
      return createPageObject('profile/personal information', 'edit personal information');
    case '/myprofile/summary':
      return createPageObject('profile/driver application/summary', 'Driver application summary');
    case '/myprofile/applyToDrive':
      return createPageObject('drivers license/entry', 'Driver application');
    case '/myprofile/change/plan':
      return createPageObject('profile/manage subscription/change subscription', 'Change Subscription');
    case '/myprofile/change/plan/upgrade':
      return createPageObject('profile/manage subscription/change subscription confirm schedule');
    case '/myprofile/change/plan/summary':
      return createPageObject('profile/manage subscription/change subscription summary', 'Change Subscription Summary');
    case '/myprofile/cancel/summary':
      return createPageObject('profile/manage subscription/cancel subscription', 'Cancel Subscription Summary');
    case '/myprofile/change/plan/licenseNotification':
      return createPageObject('profile/manage subscription/drivers license/not approved driver');
    case '/myprofile/manageSubscription':
      return createPageObject('profile/driver application/summary');
    case '/vanpool/roster':
      return createPageObject('vanpool/roster');
    case '/vanpool/overview':
      return createPageObject('vanpool/main');
    case '/agreement':
      return createPageObject('sign contract/vanpool payment',  'Find a Van', 'sign contract');
    case '/contract':
      return createPageObject('sign contract/vanpool agreement');
    case '/driver-schedule':
      return createPageObject('profile/driver schedule', 'Driver management');
    case '/driver-schedule/reviewChanges':
      return createPageObject('profile/review driver schedule', 'Driver management');
    default:
      break;
  }

  if (pathname.match(INVOICE_PATH_PATTERN)) {
    let invoiceNumber = pathname.match(INVOICE_PATH_PATTERN)[2];
    return createPageObject(`Invoice ${invoiceNumber}`, 'Payment');
  }

  if (pathname.match(EDIT_RECURRING_PAYMENT_PATH_PATTERN)) {
    let paymentNumber = pathname.match(EDIT_RECURRING_PAYMENT_PATH_PATTERN)[2];
    return createPageObject(
      `Edit Recurring Payment ${paymentNumber}`,
      'Payment'
    );
  }

  return createPageObject(pathname, 'Uncategorized');
};

////////

function createPageObject(pagename, pagecategory, process) {
  let cleanPageName = pagename.replace(/^\//, '');
  return {
    pagename: cleanPageName,
    pagecategory,
    process: process
  };
}

export default analyticsPageFactory;
