import { MONTHS } from '../../../inputs/Calendar/Calendar';

export default function DatePickerCustom(props) {
  const {days, label, placeholder, selectedDay, toggleCalendar, onCalendarKeydown, footerText} = props;

  const formattedDate = selectedDay
  ? {
      day: selectedDay.getDate(),
      month: MONTHS[selectedDay.getMonth()],
      year: selectedDay.getFullYear(),
    }
  : null;

  return (
    <div className="date-picker-custom" role='group' aria-label="Date Picker">
      <div className="plan-heading">
        {days >= 5 ? 'Unlimited Plan' : `${days}-Day Plan`}
      </div>
      <div className='picker-container'>
        <label htmlFor="date-picker-button">{label}</label>
        <button id="date-picker-button" className='picker' onClick={toggleCalendar} role='button' onKeyDown={onCalendarKeydown} aria-label={selectedDay ? `Selected date is ${formattedDate.month} ${formattedDate.day}, ${formattedDate.year}` : placeholder}>
          {formattedDate ?
            <div className='date-display'>
              <div className='day'>{formattedDate.day}</div>
              <div className='month'>{formattedDate.month}</div>
              <div className='year'>{formattedDate.year}</div>
            </div>
            :
            <div className='placeholder'>
              <span className='text'>{placeholder}</span>
            </div>
          }
          <i className="time-arrow down"></i>
        </button>
        <div className='foot-text'>{footerText}</div>
      </div>
    </div>
  );
}