import * as actionTypes from '../redux/actionTypes';
import NA_VALUE, { EMPTY_ADDRESS } from '../shared/na';

const initialState = {
  name: NA_VALUE,
  phone: null,
  secondPhone: null,
  email: NA_VALUE,
  homeAddress: EMPTY_ADDRESS,
  mailingAddress: EMPTY_ADDRESS,
  vanpoolId: '',
  vanpool: {},
  driver: {},
  loading: false,
  leftVanpool: false,
  states: [],
  statesLoaded: false,
  statesLoadingError: false,
  statesLoadingErrorString: '',
  preferences: [],
  isLoaded: false,
  loadError: false,
  loadErrorString: '',
  ratesLoaded: false,
  driverApplicationData: {},
  profileImage: null,
  subscription: {
    cancellationReasonID: null,
    lastDay: null,
    cancelled: false,
    cancelling: false,
    changed: false,
    daysChanged: false,
    typeChanged: null,
    subscriptionEndDate: null
  },
  subscriptionAPIInfo: null,
  selectedPlan: null,
  isPreferenceChanging: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_LOADING:
      return { ...state, loading: true, isLoaded: false };
    case actionTypes.LEAVE_VANPOOL:
    case actionTypes.PERSONAL_INFO_SAVING:
    case actionTypes.COMMUTE_PROFILE_SAVING:
    case actionTypes.LICENSE_SAVING:
      return { ...state, loading: true };
    case actionTypes.USER_PROFILE_LOADED:
      return { ...state, ...action.data, loading: false, isLoaded: true, loadError: false };
    case actionTypes.USER_PROFILE_LOADING_ERROR:
      return {
        ...state,
        loadError: true,
        loading: false,
        isLoaded: true,
        loadErrorString: action.errorString
      }
    case actionTypes.LEFT_VANPOOL:
      return { ...state, leftVanpool: action.data, loading: false };
    case actionTypes.CLEAR_AUTHENTICATION:
      return { ...state, leftVanpool: false, loading: false };
    case actionTypes.STATES_LOADED:
      return { ...state, states: action.data, statesLoadingError: false, statesLoaded: true };
    case actionTypes.STATES_LOADING_ERROR:
      return { ...state, states: action.data, statesLoadingError: true, statesLoaded: true, statesLoadingErrorString: action.errorString };
    case actionTypes.COMMUTE_PROFILE_SAVED:
      return { ...state, loading: false , isLoaded: false, loadError: false};
    case actionTypes.COMMUTE_USER_PROFILE_SAVED:
      return { ...state, loading: false , commuteLoaded: true};
    case actionTypes.COMMUTE_PROFILE_ERROR:
      return { ...state, loading: false };
    case actionTypes.TOGGLE_PREFERENCE:
    case actionTypes.RESET_PREFERENCE:
      return {
        ...state,
        isPreferenceChanging: true,
        preferences: state.preferences.map(pref => ({
          ...pref,
          isSelected:
            pref.preferenceCode === action.data.preferenceCode
              ? !pref.isSelected
              : pref.isSelected
        }))
      };
    case actionTypes.PREFERENCE_CHANGED:
      return { ...state, isPreferenceChanging: false };
    case actionTypes.PREFERENCE_CHANGE_ERROR:
      return { ...state, isPreferenceChanging: false };
    case actionTypes.SET_VANPOOL_RATES_INFO:
      return {
        ...state,
        vanpool: {
          ...state.vanpool,
          vanpoolRates: action.data
        },
        ratesLoaded: true
      }
    case actionTypes.SET_APPLY_TO_DRIVE_DATA:
      return {
        ...state,
        driverApplicationData: {
          apiData: action.data,
          secondaryData: action.secondaryData,
        },
      }
    case actionTypes.CLEAR_APPLY_TO_DRIVE_DATA:
      return {
        ...state,
        driverApplicationData: {}
      }
    case actionTypes.SET_CANCELLATION_REASON:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancellationReasonID: action.id
        }
      }
    case actionTypes.SET_LAST_DAY:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          lastDay: action.date
        }
      }
    case actionTypes.CANCEL_SUBSCRIPTION: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancelling: true,
        }
      }
    }
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancelled: true,
          cancelling: false,
          subscriptionEndDate: action.subscriptionEndDate,
          lastDayRiding: action.lastDayRiding
        }
      }
    case actionTypes.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancelled: false,
          cancelling: false,
        }
      }
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS_RESET:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancelled: false,
        }
      }
    case actionTypes.SUBSCRIPTION_CHANGED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          changed: true,
        }
      }
    case actionTypes.RESET_SUBSCRIPTION_CHANGED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          changed: false,
        }
      }
    case actionTypes.SET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionAPIInfo: action.data
      }
    case actionTypes.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.data
      }

    case actionTypes.LOAD_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.data.profileImage
      }
    case actionTypes.CHANGE_COMMUTE_DAYS_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          daysChanged: true
        }
      }
    case actionTypes.RESET_CHANGE_COMMUTE_DAYS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          daysChanged: false,
        }
      }
    case actionTypes.CHANGE_SUBSCRIPTION_TYPE_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          typeChanged: true,
        }
      }
    case actionTypes.CHANGE_SUBSCRIPTION_TYPE_FAILURE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          typeChanged: false,
        }
      }
    case actionTypes.RESET_CHANGE_SUBSCRIPTION_TYPE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          typeChanged: null,
        }
      }
    default:
      return state;
  }
};
