import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getActiveDaysFromRoosterDays, getFullPrice, getPackageFromDays } from '../../join-commute/helpers/vanHelpers';
import ChevronRight from '../../_styles/images/ChevronRight.png';
import CancelSubscriptionPopup from './CancelSubscriptionPopup';
import * as actionTypes from '../../redux/actionTypes';
import ErrorCircle from '../../_styles/images/new-icons/driver-status/error_circle.svg';
import DownloadIcon from '../../_styles/images/downloadBlack.svg';
import InfoIcon from '../../_styles/images/infoIcon.png';
import { getDateObjFromString } from '../../payment-history/helper';
import dateUtility from '../../shared/dateUtility';
import { getUpdatedRoosterDays } from '../../join-commute/helpers/commonHelper';
import SubscriptionSection from './common/subscription-section/SubscriptionSection';
import Callable from '../../shared/Callable';
import Http from '../../shared/http-service';
import config from '../../appConfig';
import { saveAs } from 'file-saver';
import ImageNotAvailable from '../../_styles/images/ImageNotAvailable.svg'
import analyticsService from '../../shared/analytics/analytics-service';
import { getDriverStatus } from '../helper';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  TRADITIONAL_ROSTER_ADDRESS_PERMISSION,
  PERMISSION_TRADITIONAL_USER
} from '../../shared/permissions/permissions';
import LeaveVanpoolPopup from './leaveVanpoolPopup';


function CommuteInformation(props) {
  const { commute, isDriver, isSubscription, driverPreferences, status, driverPreferencesError, isDriverSubscriptionPresentPlan } = props;
  const priceObj =
    props.subscriptionAPIInfo && props.subscriptionAPIInfo.totalAmount
      ? getFullPrice(props.subscriptionAPIInfo.totalAmount)
      : { price: '0', decimal: '0.0' };
  const plan =
    props.subscriptionAPIInfo &&
    props.subscriptionAPIInfo.package &&
    props.subscriptionAPIInfo.package.replace('X', '');
  const [showCSPopup, updateShowCSPopup] = useState(false);
  const [showChangeCommuteDaysPopup, updateShowChangeCommuteDaysPopup] = useState(false);
  const [showChangeCommuteDaysConfirmation, updateShowChangeCommuteDaysConfirmation] = useState(false);
  const [selectedOption, updateSelectedOption] = useState(null);
  const [isSubscriptionActive, updateIsSubscriptionActive] = useState(false);
  const [isSubscriptionChanged, updateIsSubscriptionChanged] = useState(false);
  const [endDate, updateEndDate] = useState('');
  const [lastRideDate, updateLastRideDate] = useState('');
  const [isCoordinator, updateIsCoordinator] = useState(false);
  const [selectedDays, updateSelectedDays] = useState([]);
  const [pendingChangeStartDate, updatePendingChangeStartDate] = useState('');
  const [isDaysChanged, updateIsDaysChanged] = useState(false);
  const [newDays, updateNewDays] = useState(false);
  const [changedPlan, updateChangedPlan] = useState({});
  const [isNewSubscription, updateIsNewSubscription] = useState(false);
  const [showDriverAppPendingLabel, updateShowDriverAppPendingLabel] = useState(false);
  const [isDriverSubscriptionNewPlan, updateIsDriverSubscriptionNewPlan] = useState(false);
  const [showLeaveVanpoolPopup, updateLeaveVanpoolPopup] = useState(false);
  const [isNewlyApprovedDriver, updateIsNewlyApprovedDriver] = useState(false);

  const selectReason = (option) => {
    updateSelectedOption(option.id)
  }

  function closeSubscriptionPopup() {
    updateShowCSPopup(false);
    updateSelectedOption(null);
  }

  function continueCancelSubscription(id) {
    props.setCancellationReason(id);
    props.getProratedAmount();
    props.history.push('/myprofile/cancel/summary');
  }

  function onCancelKeyDown(e) {
    if (e.which === 13) {
      updateShowCSPopup(true)
    }
  }

  function openChangeMyCommuteDays() {
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": 'change my commute days modal'
      }
    })
    updateShowChangeCommuteDaysPopup(true);
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        const popup = document.getElementById('change-commute-days-popup');
        if (popup) {
          updateShowChangeCommuteDaysPopup(false);
        }
        const popup1 = document.getElementById('cancel-subscription-popup');
        if (popup1) {
          updateShowCSPopup(false);
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  useEffect(() => {
    if (props.subscriptionAPIInfo) {
      if (props.subscriptionAPIInfo.package === null && props.subscriptionAPIInfo.pendingChange !== null) {
        updateIsNewSubscription(true);
        updateIsSubscriptionActive(false);
        updateIsDriverSubscriptionNewPlan(props.subscriptionAPIInfo.pendingChange.isDriverDiscount);
        const newPlan = props.subscriptionAPIInfo.pendingChange;
        newPlan.plan = props.subscriptionAPIInfo.pendingChange.package && props.subscriptionAPIInfo.pendingChange.package.replace('X', '');
        newPlan.priceObj = props.subscriptionAPIInfo.pendingChange.totalAmount
          ? getFullPrice(props.subscriptionAPIInfo.pendingChange.totalAmount)
          : { price: '0', decimal: '0.0' };
        updateChangedPlan(newPlan);
        const days = getActiveDaysFromRoosterDays(props.subscriptionAPIInfo.pendingChange.participantCommuteDays);
        updateNewDays(days);
        const startDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.subscriptionAPIInfo.pendingChange.startDate);
        updatePendingChangeStartDate(startDay);
      } else {
        const isCoordinator = props.subscriptionAPIInfo.participantRole && props.subscriptionAPIInfo.participantRole.includes("C");
        updateIsCoordinator(isCoordinator);
        if ((props.subscriptionAPIInfo.endDate || props.subscriptionAPIInfo.lastRideDate) && props.subscriptionAPIInfo.pendingChange === null) {
          updateIsSubscriptionActive(false);
        } else if ((props.subscriptionAPIInfo.endDate || props.subscriptionAPIInfo.lastRideDate) && props.subscriptionAPIInfo.pendingChange) {
          updateIsSubscriptionChanged(true);
          updateIsSubscriptionActive(true);
          const hasDriverRole = props.subscriptionAPIInfo.participantRole && props.subscriptionAPIInfo.participantRole.includes("D");
          const driverStatus = getDriverStatus(status);
          const isPendingApplication = driverStatus.status === 'pending';
          const isApproved = driverStatus.status === 'approved';
          updateIsDriverSubscriptionNewPlan(props.subscriptionAPIInfo.pendingChange.isDriverDiscount);
          //checking if the changed subscription is rider to driver
          if (hasDriverRole && isPendingApplication && props.subscriptionAPIInfo.pendingChange.isDriverDiscount === false && !isDriverSubscriptionPresentPlan) {
            updateShowDriverAppPendingLabel(true);
          }
          if(props.subscriptionAPIInfo.pendingChange.isDriverDiscount && isApproved){
            updateIsNewlyApprovedDriver(true);
          } else {
            updateIsNewlyApprovedDriver(false);
          }
          const newPlan = props.subscriptionAPIInfo.pendingChange;
          newPlan.plan = props.subscriptionAPIInfo.pendingChange.package.replace('X', '');
          newPlan.priceObj = props.subscriptionAPIInfo.pendingChange.totalAmount
            ? getFullPrice(props.subscriptionAPIInfo.pendingChange.totalAmount)
            : { price: '0', decimal: '0.0' };
          updateChangedPlan(newPlan);
          const startDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.subscriptionAPIInfo.pendingChange.startDate);
          updatePendingChangeStartDate(startDay);
          const days = getActiveDaysFromRoosterDays(props.subscriptionAPIInfo.pendingChange.participantCommuteDays);
          updateNewDays(days);
        } else if (!props.subscriptionAPIInfo.endDate && props.subscriptionAPIInfo.pendingChange) {
          updateIsDaysChanged(true);
          updateIsSubscriptionActive(true);
          const startDay = dateUtility.parseDateAsMonthDayYearFromGMT(props.subscriptionAPIInfo.pendingChange.startDate);
          updatePendingChangeStartDate(startDay);
          const days = getActiveDaysFromRoosterDays(props.subscriptionAPIInfo.pendingChange.participantCommuteDays);
          updateNewDays(days);
        } else {
          updateIsSubscriptionActive(true);
        }
      }
        const endDateObj = props.subscriptionAPIInfo.endDate && getDateObjFromString(props.subscriptionAPIInfo.endDate);
        const lastRideDateObj = props.subscriptionAPIInfo.lastRideDate && getDateObjFromString(props.subscriptionAPIInfo.lastRideDate);
        endDateObj && updateEndDate(`${endDateObj.month} ${endDateObj.day}, ${endDateObj.year}`);
        lastRideDateObj && updateLastRideDate(`${lastRideDateObj.month} ${lastRideDateObj.day}, ${lastRideDateObj.year}`);
    }
  }, [props.subscriptionAPIInfo])

  function saveCommuteDays(days, date) {
    updateSelectedDays(days);
    const dateStr = dateUtility.parseDateAsMonthDayYearFromGMT(date);
    updatePendingChangeStartDate(dateStr);
    const effectiveDate = dateUtility.getDateStringFromDay(date);
    const rosterDays = getUpdatedRoosterDays(days);
    const packName = getPackageFromDays(plan);
    const data = {
      effectiveDate,
      packageDiscountType: isDriver ? "Driver" : "Rider",
      packageName: packName,
      rosterDays,
      subscriptionType: "Fixed",
      vanpoolId: props.vanpoolInfo.id,
      isDriver
    }
    updateShowChangeCommuteDaysPopup(false);
    props.changeCommuteDays(data);
  }

  useEffect(() => {
    if (props.isCommuteDaysChanged) {
      updateShowChangeCommuteDaysConfirmation(true);
    }
  }, [props.isCommuteDaysChanged]);

  function gotoChangeSubscription() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "change subscription plan"
      }
    });
    props.history.push('/myprofile/change/plan');
  }

  function checkEnter(e, callBack, params) {
    if (e.which === 13) {
      callBack(...params);
    }
  }

  async function downloadAgreement(str) {
    analyticsService.analyticsProcessEvent(
      {
        "event": "download_driver_agreement_download",
        "context": {
          "event_action": str,
          "file_name": "agreement.pdf"
        }
      });
    let response = await Http.getStatic(
      `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/contract`, { "Content-Type": "application/pdf" });
    if (response.ok) {
      const pdf = await response.blob();
      if (pdf && pdf.type.indexOf('error') == -1) {
        saveAs(pdf, "agreement.pdf");
      } else {
        props.showError();
      }

    } else {
      props.showError();
    }
  }

  function onCancelSubscription() {
    props.passAnalyticsClickEvent('cancel subscription');
    updateShowCSPopup(true);
  }

  function saveSchedulePreferences(data) {
    props.updateDriverPreferences(data);
  }

  function leaveVanpool() {
    var id = props.participantId;
    props.leaveVanpool(id);
  }

  function clickedLeaveVanpool() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "leave vanpool"   
      }
    });
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "leave this vanpool modal"   
      }
    });
    updateLeaveVanpoolPopup(true)
  }

  useEffect(() => {
    if (props.leftVanpool) {
      analyticsService.analyticsProcessEvent({
        "event": "modal_view_generic",
        "context": {
          "event_action": "successfully left vanpool modal"   
        }
      });
      updateLeaveVanpoolPopup(true);
    }
  }, [props.leftVanpool]);

  useEffect(() => {
    if (props.driverPreferencesUpdateSuccess === false) {
      props.showToast('There was an error while updating your driving preferences. Please try again', 'error');
      props.resetDriverPreferencessUpdate();
    } else if (props.driverPreferencesUpdateSuccess === true) {
      props.showToast('Your driving preferences were updated successfully', 'success');
      props.resetDriverPreferencessUpdate();
    }
  }, [props.driverPreferencesUpdateSuccess])




  return (
    <div className="commute-information" role='group'>
      <div className="main-content">
        <div className="first-section">
          <div className="header">
            My Commute
          </div>
          <div className='section'>
            <div className="left-section">
              <div className="van-class-details" role='group'>
                <div className="van-class">
                  {commute.vehicleType}
                </div>
                <div className="van-name">
                  {commute.name}
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className={`${props.vanpoolStatus === 'A' || props.vanpoolStatus === 'P' ? "car-image-opacity" : "car-image"}`}>
                <img src={commute.vanpoolImage} onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                }} alt='van image' />
              </div>
            </div>
          </div>
          <RestrictTo roles={[TRADITIONAL_ROSTER_ADDRESS_PERMISSION]} required={false} >
            <div className='traditional-agreement'>
              <div className="text">
                To obtain a copy of your signed Driver or Coordinator agreement please call <Callable className="greentext" phone="800 VAN-4-WORK" />
              </div>
            </div>
          </RestrictTo>
          <RestrictTo roles={[PERMISSION_TRADITIONAL_USER]} required={false} >
            <div className='traditional-leave-Vanpool' onClick={() => clickedLeaveVanpool()}>
              {"Leave Vanpool"}
            </div>
          </RestrictTo>
        </div>
        {
          isSubscription ?
            <>
              <div className="seprator"></div>
              {
                isSubscriptionActive ?
                  <>
                    <div className={isSubscriptionActive ? "second-section active" : "second-section inactive"}>
                      <div className="header" >
                        Manage Subscription
                      </div>
                      {
                        isSubscriptionChanged ?
                          <div>
                            <div className='notification-container'>
                              <div className='info-icon'>
                                <img className='tick-icon' src={InfoIcon} aria-hidden='true' />
                              </div>
                              <div className='notification-text'>Your new subscription plan details will be available after your current plan ends on {endDate}<span aria-hidden='true'>.</span><br />
                                Please call <Callable phone="800 VAN 4 WORK" /> if you need to make changes to this plan. </div>
                            </div>
                            <SubscriptionSection
                              badgeStatus='new'
                              badgeText='New Plan'
                              background='active'
                              showDriverAppPendingLabel={showDriverAppPendingLabel}
                              isSubscriptionActive={isSubscriptionActive}
                              isCoordinator={isCoordinator}
                              plan={changedPlan.plan}
                              days={newDays}
                              startText={pendingChangeStartDate ? `Starting ${pendingChangeStartDate}` : null}
                              priceObj={changedPlan.priceObj}
                              isDriver={isDriverSubscriptionNewPlan}
                              showAgreemnet={true}
                              downloadAgreement={downloadAgreement}
                              openChangeMyCommuteDays={openChangeMyCommuteDays}
                              isSubscriptionChanged={isSubscriptionChanged}
                              showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                              showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                              saveCommuteDays={saveCommuteDays}
                              selectedDays={selectedDays}
                              subscription={props.subscriptionAPIInfo}
                              pendingChangeStartDate={pendingChangeStartDate}
                              updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                              vanpoolInfo={props.vanpoolInfo}
                              updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                              driverPreferences={driverPreferences}
                              status={status}
                              saveSchedulePreferences={saveSchedulePreferences}
                              getDriverPreferences={props.getDriverPreferences}
                              driverPreferencesError={driverPreferencesError}
                              passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                              driverInfo={props.driverInfo}
                            />
                            <div className="seprator"></div>
                            <SubscriptionSection
                              badgeStatus='active'
                              badgeText='Current Plan'
                              background='inactive'
                              isNewlyApprovedDriver={isNewlyApprovedDriver}
                              isSubscriptionActive={isSubscriptionActive}
                              isCoordinator={isCoordinator}
                              package={props.subscriptionAPIInfo.package}
                              plan={plan}
                              days={isDaysChanged ? newDays : commute && commute.commuteDays && commute.commuteDays.length ? commute.commuteDays : []}
                              startText={isDaysChanged && pendingChangeStartDate ? `Starting ${pendingChangeStartDate}` : null}
                              priceObj={priceObj}
                              isDriver={isDriverSubscriptionPresentPlan}
                              showAgreemnet={false}
                              endText={endDate ? `Ending ${endDate}` : null}
                              openChangeMyCommuteDays={openChangeMyCommuteDays}
                              isSubscriptionChanged={isSubscriptionChanged}
                              showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                              showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                              saveCommuteDays={saveCommuteDays}
                              selectedDays={selectedDays}
                              subscription={props.subscriptionAPIInfo}
                              pendingChangeStartDate={pendingChangeStartDate}
                              updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                              vanpoolInfo={props.vanpoolInfo}
                              updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                              driverPreferences={driverPreferences}
                              status={status}
                              saveSchedulePreferences={saveSchedulePreferences}
                              getDriverPreferences={props.getDriverPreferences}
                              driverPreferencesError={driverPreferencesError}
                              passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                              driverInfo={props.driverInfo}
                            />
                            {
                              isNewlyApprovedDriver && <div className='newly-approved-driver'>*If you are a newly approved driver, you may enjoy the driver rate for the remainder of your current plan.</div>
                            }
                          </div>
                          :
                          (isDaysChanged ?
                            <SubscriptionSection
                              badgeStatus='active'
                              badgeText='Current Plan'
                              background='active'
                              isSubscriptionActive={isSubscriptionActive}
                              isCoordinator={isCoordinator}
                              plan={plan}
                              days={newDays}
                              startText={pendingChangeStartDate ? `Starting ${pendingChangeStartDate}` : null}
                              priceObj={priceObj}
                              isDriver={isDriverSubscriptionPresentPlan}
                              openChangeMyCommuteDays={openChangeMyCommuteDays}
                              isSubscriptionChanged={isSubscriptionChanged}
                              showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                              showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                              saveCommuteDays={saveCommuteDays}
                              selectedDays={selectedDays}
                              subscription={props.subscriptionAPIInfo}
                              pendingChangeStartDate={pendingChangeStartDate}
                              updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                              vanpoolInfo={props.vanpoolInfo}
                              updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                              driverPreferences={driverPreferences}
                              status={status}
                              saveSchedulePreferences={saveSchedulePreferences}
                              getDriverPreferences={props.getDriverPreferences}
                              driverPreferencesError={driverPreferencesError}
                              passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                              driverInfo={props.driverInfo}
                            />
                            :
                            <SubscriptionSection
                              badgeStatus='active'
                              badgeText='Current Plan'
                              background='active'
                              isSubscriptionActive={isSubscriptionActive}
                              isCoordinator={isCoordinator}
                              plan={plan}
                              days={commute && commute.commuteDays && commute.commuteDays.length ? commute.commuteDays : []}
                              priceObj={priceObj}
                              isDriver={isDriverSubscriptionPresentPlan}
                              openChangeMyCommuteDays={openChangeMyCommuteDays}
                              isSubscriptionChanged={isSubscriptionChanged}
                              showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                              showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                              saveCommuteDays={saveCommuteDays}
                              selectedDays={selectedDays}
                              subscription={props.subscriptionAPIInfo}
                              pendingChangeStartDate={pendingChangeStartDate}
                              updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                              vanpoolInfo={props.vanpoolInfo}
                              updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                              driverPreferences={driverPreferences}
                              status={status}
                              saveSchedulePreferences={saveSchedulePreferences}
                              getDriverPreferences={props.getDriverPreferences}
                              driverPreferencesError={driverPreferencesError}
                              passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                              driverInfo={props.driverInfo}
                            />)

                      }
                    </div>
                    <div className='third-section'>
                      {!isSubscriptionChanged &&
                        <div className="subscription-action-items">
                          <div className="subscription-items" tabIndex={0} role='button' onClick={() => gotoChangeSubscription()} onKeyDown={(e) => checkEnter(e, updateShowChangeCommuteDaysPopup, [true])}>
                            <span>Change Subscription Plan</span>
                            <img src={ChevronRight} />
                          </div>
                          <div className="subscription-items" tabIndex={0} role='button' onClick={onCancelSubscription} onKeyDown={onCancelKeyDown}>
                            <span>Cancel Subscription</span>
                            <img src={ChevronRight} />
                          </div>
                        </div>
                      }
                    </div>
                    {
                      <div className='viewAgreement' onClick={() => downloadAgreement(isDriver ? "Download Driver Agreement" : "Download Rider Agreement")}>
                        <img src={DownloadIcon} alt=''></img>
                        {/* <span>{isDriver ? "Download Driver Agreement" : "Download Rider Agreement"}</span> */}
                        <span>Download vanpool Agreement</span>
                      </div>
                    }
                  </>
                  :
                  isNewSubscription ?
                    <div className='second-section active'>
                      <SubscriptionSection
                        badgeStatus='new'
                        badgeText='New Plan'
                        background='active'
                        isSubscriptionActive={isSubscriptionActive}
                        isCoordinator={false}
                        plan={changedPlan.plan}
                        days={newDays}
                        startText={pendingChangeStartDate ? `Starting ${pendingChangeStartDate}` : null}
                        priceObj={changedPlan.priceObj}
                        isDriver={isDriverSubscriptionNewPlan}
                        showAgreemnet={true}
                        downloadAgreement={downloadAgreement}
                        openChangeMyCommuteDays={openChangeMyCommuteDays}
                        isSubscriptionChanged={isSubscriptionChanged}
                        showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                        showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                        saveCommuteDays={saveCommuteDays}
                        selectedDays={selectedDays}
                        subscription={props.subscriptionAPIInfo}
                        pendingChangeStartDate={pendingChangeStartDate}
                        updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                        vanpoolInfo={props.vanpoolInfo}
                        updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                        driverPreferences={driverPreferences}
                        status={status}
                        saveSchedulePreferences={saveSchedulePreferences}
                        getDriverPreferences={props.getDriverPreferences}
                        driverPreferencesError={driverPreferencesError}
                        passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                        driverInfo={props.driverInfo}
                        isNewSubscription={isNewSubscription}
                      />
                      <div className='notification-container'>
                        <div className='info-icon'>
                          <img className='tick-icon' src={InfoIcon} aria-hidden='true' />
                        </div>
                        <div className='notification-text'>Your new subscription plan starts on {pendingChangeStartDate}<span aria-hidden='true'>. </span>
                          If you need to make changes to this plan please call <Callable phone="800 VAN 4 WORK" /></div>
                      </div>
                    </div>
                    :
                    (isCoordinator ?
                      <>
                        <SubscriptionSection
                          badgeStatus='inactive'
                          badgeText='Current Plan - Pending Cancellation'
                          background='inactive'
                          isSubscriptionActive={isSubscriptionActive}
                          isCoordinator={isCoordinator}
                          plan={plan}
                          days={commute && commute.commuteDays && commute.commuteDays.length ? commute.commuteDays : []}
                          priceObj={priceObj}
                          isDriver={isDriverSubscriptionPresentPlan}
                          endDate = {endDate}
                          lastRideDate = {lastRideDate}
                          showHeader={true}
                          showAgreemnet={true}
                          downloadAgreement={downloadAgreement}
                          openChangeMyCommuteDays={openChangeMyCommuteDays}
                          isSubscriptionChanged={isSubscriptionChanged}
                          showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                          showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                          saveCommuteDays={saveCommuteDays}
                          selectedDays={selectedDays}
                          subscription={props.subscriptionAPIInfo}
                          pendingChangeStartDate={pendingChangeStartDate}
                          updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                          vanpoolInfo={props.vanpoolInfo}
                          updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                          driverPreferences={driverPreferences}
                          status={status}
                          saveSchedulePreferences={saveSchedulePreferences}
                          getDriverPreferences={props.getDriverPreferences}
                          driverPreferencesError={driverPreferencesError}
                          passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                          driverInfo={props.driverInfo}
                          showAgreement={true}
                        />
                        <div className='third-section'>
                          <div className='cancelled-container'>
                            <div className={'status-content expired'}>
                              <div className='warning-icon'>
                                <img className='tick-icon' src={ErrorCircle} aria-hidden='true' />
                              </div>
                              <div className='content-text'>
                                <div className='warning-heading'>
                                  Cancellation pending
                                </div>
                                We’ve received your cancellation request. A representative will contact you shortly to get your subscription canceled.
                              </div>
                            </div>
                          </div>
                        </div>

                      </>
                      :
                      <SubscriptionSection
                        badgeStatus='inactive'
                        badgeText='Current Plan - Cancelled'
                        background='inactive'
                        isSubscriptionActive={isSubscriptionActive}
                        isCoordinator={isCoordinator}
                        plan={plan}
                        days={commute && commute.commuteDays && commute.commuteDays.length ? commute.commuteDays : []}
                        priceObj={priceObj}
                        isDriver={isDriver}
                        endDate = {endDate}
                        lastRideDate = {lastRideDate}
                        showHeader={true}
                        showAgreemnet={true}
                        downloadAgreement={downloadAgreement}
                        openChangeMyCommuteDays={openChangeMyCommuteDays}
                        isSubscriptionChanged={isSubscriptionChanged}
                        showChangeCommuteDaysPopup={showChangeCommuteDaysPopup}
                        showChangeCommuteDaysConfirmation={showChangeCommuteDaysConfirmation}
                        saveCommuteDays={saveCommuteDays}
                        selectedDays={selectedDays}
                        subscription={props.subscriptionAPIInfo}
                        pendingChangeStartDate={pendingChangeStartDate}
                        updateShowChangeCommuteDaysConfirmation={updateShowChangeCommuteDaysConfirmation}
                        vanpoolInfo={props.vanpoolInfo}
                        updateShowChangeCommuteDaysPopup={updateShowChangeCommuteDaysPopup}
                        driverPreferences={driverPreferences}
                        status={status}
                        saveSchedulePreferences={saveSchedulePreferences}
                        getDriverPreferences={props.getDriverPreferences}
                        driverPreferencesError={driverPreferencesError}
                        passAnalyticsClickEvent={props.passAnalyticsClickEvent}
                        driverInfo={props.driverInfo}
                        showAgreement={true}
                      />)
              }
            </>
            :
            null
        }

      </div>
      {showCSPopup && <CancelSubscriptionPopup selected={props.reasonID} continueClicked={continueCancelSubscription} close={closeSubscriptionPopup} selectReason={selectReason} selectedOption={selectedOption} />}
      {showLeaveVanpoolPopup && <LeaveVanpoolPopup close={() => updateLeaveVanpoolPopup(false)} leave={leaveVanpool} isCoordinator={props.isCoordinator} leftVanpool={props.leftVanpool} />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    subscriptionAPIInfo: state.profile.subscriptionAPIInfo,
    vanpoolInfo: state.profile.vanpool,
    isCommuteDaysChanged: state.profile && state.profile.subscription && state.profile.subscription.daysChanged,
    driverPreferencesUpdateSuccess: state.driverSchedule.updatePreferencesSuccess,
    participantId: state.profile.participantId,
    isCoordinator: state.profile.isCoordinator,
    leftVanpool: state.profile.leftVanpool,
    dashboard: state.dashboard,
    vanpoolStatus: state.userAuth.vanpoolStatus
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCancellationReason(id) {
      dispatch({ type: actionTypes.SET_CANCELLATION_REASON, id });
    },
    changeCommuteDays(data) {
      dispatch({ type: actionTypes.CHANGE_COMMUTE_DAYS, data });
    },
    showError() {
      dispatch({ type: actionTypes.DISPLAY_ERROR, data: 'No Document found' });
    },
    resetDriverPreferencessUpdate() {
      dispatch({ type: actionTypes.UPDATE_DRIVER_PREFERENCES_RESET });
    },
    getProratedAmount() {
      dispatch({ type: actionTypes.LOAD_PRORATED_INFO });
    },
    leaveVanpool(id) {
      dispatch({ type: actionTypes.LEAVE_VANPOOL, data: id });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommuteInformation);
