import { Link } from 'react-router-dom';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import { useEffect, useRef, useState } from 'react';
import { getDaysFromPackage, getFullPrice, getPackageFromDays } from '../../../join-commute/helpers/vanHelpers';
import { getSortedCards } from '../../../payment/helper';
import { assignPriceToCards } from '../../../shared/helpers/cardHelpers';
import { connect } from 'react-redux';
import Calendar from '../../../shared/inputs/Calendar/Calendar';
import LeftSection from '../../../shared/components/summary/left-section/LeftSection';
import SummaryPaymentCards from '../../../shared/components/summary/payment-cards/SummaryPaymentCards';
import DatePickerCustom from '../../../shared/components/summary/date-picker/DatePickerCustom';
import * as actionTypes from '../../../redux/actionTypes';
import DiscardChanges from '../common/discardChangesPopup/DiscardChanges';
import dateUtility from '../../../shared/dateUtility';
import CancelConfirmationPopup from './CancelConfirmationPopup';
import Loading from '../../../shared/loading/Loading';
import ImageNotAvailable from '../../../_styles/images/ImageNotAvailable.svg';
import { getEndDateOfCancellation } from '../../../shared/helpers/dateHelpers';
import analyticsService from '../../../shared/analytics/analytics-service';
import PriceDisclaimerPopup from '../../../join-commute/subscription/PriceDisclaimerPopup';

function CancelSummary(props) {

  const node = useRef(null);
  const navigationAlertNode = useRef(null);

  const [days, updateDays] = useState();
  const [isDriver, updateIsDriver] = useState(false);
  const [cards, updateCards] = useState([]);
  const [van, updateVan] = useState();
  const [subscription, updateSubscription] = useState();
  const [tax, updateTax] = useState();
  const [addressDetails, updateAddressDetails] = useState();
  const [selectedDay, updateSelectedDay] = useState();
  const [subscriptionEndDate, updateSubscriptionEndDate] = useState();
  const [lastDayRiding, updateLastDayRiding] = useState();
  const [isOpen, updateIsOpen] = useState(false);
  const startDay = new Date(new Date().setHours(0, 0, 0, 0));
  const endDay = getEndDateOfCancellation(startDay);
  const [showCCPopup, updateShowCCPopup] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [showPDPopup, updatePDPopup] = useState(false);


  function toggleCalendar() {
    if (!isOpen) {

      const modal = document.getElementById("modal");
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "true");
      updateIsOpen(true);
      if (modal) {
        modal?.setAttribute("aria-modal", "true");
        modal?.querySelector(".calendar-day")?.focus();
      }
    } else {
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }

  }


  function onCalendarKeydown(e) {
    if (e.which == 13) {
      toggleCalendar();
    }
  }

  function checkAndCloseCalendar() {
    const modal = document.getElementById("modal");
    const classes = Array.from(modal.classList);
    if (classes.includes('show')) {
      const all = document.querySelector(".vanpool-summary");
      all?.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }
  }

  const clickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    toggleCalendar();
  }

  useEffect(() => {
    if (props.profile) {
      const subscriptionDetails = props.profile.subscriptionAPIInfo;
      updateIsDriver(props.profile.isDriver);
      updateVan(props.profile.vanpool);
      if (props.profile.subscriptionAPIInfo) {
        const price = subscriptionDetails && subscriptionDetails.totalAmount
          ? getFullPrice(subscriptionDetails.totalAmount)
          : { price: '0', decimal: '0.0' };
        
        const rate = subscriptionDetails.subscriptionRate;
        updateSubscription({...price, rate});
        updateTax(subscriptionDetails.taxAmount);
        const days = getDaysFromPackage(subscriptionDetails.package);
        updateDays(days);
      }
      const totalPrice = subscriptionDetails && parseFloat(subscriptionDetails.totalAmount);
      if (totalPrice && props.proratedInfo && props.proratedInfo.storedPaymentCards) {
        const sortedCards = getSortedCards(props.proratedInfo.storedPaymentCards);
        const chargedCards = assignPriceToCards(totalPrice, sortedCards);
        updateCards(chargedCards);
      }
      if (props.profile.commuteDetails) {
        updateAddressDetails(props.profile.commuteDetails);
      }
    }
  }, [props.profile, props.proratedInfo, props.profile.subscriptionAPIInfo])

  


  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', clickOutside);
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }
  }, [isOpen])

  function cancelSubscription() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": 'confirm cancellation'
       }
    });
    const dayString = dateUtility.getDateStringFromDay(selectedDay);
    props.setLastDay(dayString);
    props.cancelSubscription(props.history, dayString);
  }

  useEffect(() => {
    if (!(props.profile && props.profile.subscription && props.profile.subscription.cancellationReasonID !== null)) {
      props.history.replace('/myprofile');
    }
    const close = (e) => {
      if (e.keyCode === 27) {
        checkAndCloseCalendar()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  function closeCancelSubscriptionPopup() {
    updateShowCCPopup(false);
    props.history.push('/myprofile');
  }

  useEffect(() => {
    if (props.cancelling) {
      updateLoading(true);
    } else {
      updateLoading(false);
    }
  }, [props.cancelling])

  useEffect(() => {
    if (props.cancelled && props.subscriptionEndDate && props.lastDayRiding) {
      updateSubscriptionEndDate(props.subscriptionEndDate);
      updateLastDayRiding(props.lastDayRiding);
      updateShowCCPopup(true);
      updateLoading(false);
    } else {
      updateShowCCPopup(false);
    }
  }, [props.cancelled, props.subscriptionEndDate, props.lastDayRiding])

  return (
    <div className='final-summary-container cancel-summary-container'>
      <Loading isLoading={loading} />
      <div className="final-summary-page cancel-summary-page">
        <div className='header'>
          <div className='backArrow'>
            <div className="overview-link" onClick={props.history.goBack}>
              <img alt='go back' src={ArrowLeft}></img>
            </div>
          </div>
          <div className='heading'>Cancel Subscription</div>
        </div>
        {van && addressDetails && <div className="vanpool-summary" role='group'>
          <LeftSection van={van} subscription={subscription} isDriver={isDriver} tax={tax} addressDetails={addressDetails} showRideDays={false} routeDetails={{
            originAddress: addressDetails.originAddress,
            destinationAddress: addressDetails.destinationAddress,
            matchedOriginStop: {
              departureTimestamp: addressDetails.originDepartureTime,
              arrivalTimestamp: addressDetails.originArrivalTime
            },
            matchedDestinationStop: {
              departureTimestamp: addressDetails.destinationDepartureTime,
              arrivalTimestamp: addressDetails.destinationArrivalTime
            }
          }} updatePDPopup= {() => updatePDPopup(true)} 
          taxes={props.taxes} 
          surcharges={props.surcharges}
          totalAmount={props.totalAmount}
          totalTax={parseFloat(props.taxAmount)+parseFloat(props.surchargeAmount)}
          cancelSummary={true}/>
          <div className="vanpool-summary-image">
            <div className="van-Image">
              <div className='car-image'>
                <img src={van.vanpoolImage} onError={e => {
                    e.currentTarget.src = ImageNotAvailable;
                    e.currentTarget.className = "image-not-found";
                    }} alt='van image' />
              </div>
            </div>
          </div>
          <div className="vanpool-summary-plan">
            <DatePickerCustom
              selectedDay={selectedDay}
              days={days}
              toggleCalendar={toggleCalendar} 
              onCalendarKeydown={onCalendarKeydown}
              placeholder='Select Last Date'
              label='Last day you’ll ride with the vanpool'
              footerText='Changes must be made 30 days in advance. If you cancel with less than 30 days’ notice, you will still be charged the applicable rate for 30 days.' />
          </div>
          <SummaryPaymentCards cards={cards} />
        </div>}
        <div aria-live='polite' role='alert' id='modal' className={"popup" + (isOpen ? " show" : " hide")}>
          <Calendar
            reference={node}
            buttonText={'confirm last date'}
            toggle={toggleCalendar}
            selectedDay={selectedDay}
            onConfirm={updateSelectedDay}
            startDay={startDay}
            endDay={endDay}
            reset={isOpen}
            enabledDays={[0, 1, 2, 3, 4, 5, 6]}
          />
        </div>
        <DiscardChanges
          heading='Unsaved Changes'
          text='Are you sure you want to continue? Any unsaved changes will be lost.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, continue'
          when={true}
          navigate={path => props.history.replace(path)}
          shouldBlockNavigation={location => {
            if (!!selectedDay && !props.cancelled) {
              if (location.pathname !== '/myprofile/cancel/summary') {
                return true
              }
              if(props.cancelled){
                return false;
              }
            } else if(props.cancelled){
              return false;
            }
          }}
          handleValidation={() => { return !(props.profile && props.profile.subscription && props.profile.subscription.cancellationReasonID !== null) }}
          reference={navigationAlertNode}
        />
        <div className='button-container'>
          <button className='save-button' onClick={cancelSubscription} disabled={!selectedDay}>confirm cancellation</button>
        </div>
      </div>
      {
        showCCPopup && <CancelConfirmationPopup
        close={closeCancelSubscriptionPopup}
        details={{...props.profile }}
        history={props.history}
        subscriptionEndDate={subscriptionEndDate}
        lastDayRiding={lastDayRiding}/>
      }
      {
        showPDPopup &&  <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
      }
    </div>
  )
}

function mapStateToProps(state) {
  return {
    profile: state?.profile,
    cancelling: state?.profile?.subscription?.cancelling,
    cancelled: state?.profile?.subscription?.cancelled,
    subscriptionEndDate: state?.profile?.subscription?.subscriptionEndDate,
    lastDayRiding: state?.profile?.subscription?.lastDayRiding,
    taxes: state?.profile?.subscriptionAPIInfo?.taxes,
    surcharges: state?.profile?.subscriptionAPIInfo?.surcharges,
    totalAmount: state?.profile?.subscriptionAPIInfo?.totalAmount,
    surchargeAmount: state?.profile?.subscriptionAPIInfo?.surchargeAmount,
    taxAmount: state?.profile?.subscriptionAPIInfo?.taxAmount,
    proratedInfo: state?.participants?.proratedInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLastDay(date) {
      dispatch({ type: actionTypes.SET_LAST_DAY, date });
    },
    cancelSubscription(history) {
      dispatch({ type: actionTypes.CANCEL_SUBSCRIPTION, history });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSummary)